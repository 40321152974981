<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Update BOQ Prices</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field
          label="% to increase / decrease BOQ prices"
          step="0.01"
          suffix="%"
          type="number"
          v-model="uplift"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <p>This will increase / decrease all BOQ prices across the system. This cannot be undone.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-btn @click="saveUpdate = true">Save update</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="saveUpdate"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Confirm Price Update
        </v-card-title>
        <v-card-text class="pt-4">
          The price of all BOQs will be updated by {{ uplift }}%
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green lighten-2"
            small
            @click="confirmUpdate"
          >
            Confirm
          </v-btn>
          &nbsp;
          <v-btn
            small
            @click="saveUpdate = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="$router.push('/admin/boqs/index')"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'UpdateBoqPrices',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      message: '',
      saveUpdate: false,
      showMessage: false,
      uplift: 0,
    };
  },
  methods: {
    confirmUpdate() {
      axios.post(`/boqs/updatePrices.json?token=${this.token}&userid=${this.userid}`, this.uplift)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
